import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    const difference = +new Date(`${new Date().getFullYear()}-12-31`) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400">
      <div className="bg-white bg-opacity-80 rounded-xl shadow-2xl p-8 m-4">
        <h1 className="text-4xl font-bold text-gray-800 mb-2">Coming Soon</h1>
        <h2 className="text-xl font-bold text-gray-800 mb-8">(December 31, 2024)</h2>
        <div className="flex space-x-6">
          {Object.entries(timeLeft).length ? (
            Object.entries(timeLeft).map(([interval, value]) => (
              <div key={interval} className="flex flex-col items-center">
                <span className="text-5xl font-bold text-indigo-600">{value}</span>
                <span className="text-sm uppercase text-gray-500 mt-2">{interval}</span>
              </div>
            ))
          ) : (
            <span className="text-2xl font-semibold text-gray-800">Time's up!</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
